import React, { useCallback, useEffect, useMemo } from "react";
import {
    Box,
    Step,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { PrimaryPageStep } from "../state/primaryPageStep";
import { steps } from "../steps";
import { useLocation } from "react-router-dom";

const Navigator = () => {
    const location = useLocation();
    const [selectedStep, setSelectedStep] = useRecoilState(PrimaryPageStep);

    const onManualScroll = useCallback(
        () => setSelectedStep(-1),
        [setSelectedStep]
    );

    useEffect(() => {
        document.addEventListener("scroll", onManualScroll);
        return () => {
            document.removeEventListener("scroll", onManualScroll);
        };
    }, [onManualScroll]);

    const isMainPage = useMemo(
        () => location.pathname === "/",
        [location.pathname]
    );

    useEffect(() => {
        if (isMainPage) {
            const selectedAnchor = steps.find(
                (s) => s.id === selectedStep
            )?.anchor;
            if (selectedAnchor) {
                setSelectedStep(-1);
                document.getElementById(selectedAnchor)?.scrollIntoView({
                    block: "start",
                    inline: "start",
                    behavior: "smooth",
                });
            }
        }
    }, [isMainPage, selectedStep, setSelectedStep]);

    return (
        <Stepper
            index={selectedStep}
            orientation="vertical"
            h="100%"
            gap={0}
            minW="fit-content"
            size="lg"
        >
            {steps.map(({ label, Icon, id }) => (
                <Step key={id} onClick={() => setSelectedStep(id)}>
                    <StepIndicator>
                        <StepStatus
                            complete={<Icon />}
                            incomplete={<Icon />}
                            active={<Icon />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0" mt={1} userSelect="none">
                        <StepTitle>{label}</StepTitle>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    );
};

export default Navigator;
