import React from "react";
import { Spacer, VStack } from "@chakra-ui/react";
import Header from "./Header";
import { steps } from "../steps";

const Page = () => {
    return (
        <VStack w="100%" spacing={8} wordBreak="break-word">
            <Header />
            {steps.map(({ SectionElement, id }) => (
                <SectionElement key={id} />
            ))}
            <Spacer minH={20} />
        </VStack>
    );
};

export default Page;
