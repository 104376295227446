import { useBreakpoint } from "@chakra-ui/react";
import { useMemo } from "react";

export function useIsSmallScreen() {
    const screenSize = useBreakpoint();
    return useMemo(
        () =>
            screenSize === "md" || screenSize === "sm" || screenSize === "base",
        [screenSize]
    );
}
