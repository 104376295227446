import React, { useCallback, useMemo, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Editable,
    EditableInput,
    EditablePreview,
    HStack,
    IconButton,
    List,
    ListItem,
    Select,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { ITable, ITableParty } from "../types";
import { BsXCircleFill } from "react-icons/bs";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { isNil } from "../api/is-nil";

interface ITableCardRowProps {
    party: ITableParty;
    onRemove(): void;
}

const TableCardRow = (props: ITableCardRowProps) => {
    const { party, onRemove } = props;

    return (
        <HStack justifyContent="space-between">
            <Text>{party.name}</Text>
            <IconButton
                icon={<BsXCircleFill />}
                aria-label="Remove"
                onClick={onRemove}
            />
        </HStack>
    );
};

interface ITableCardProps {
    table: ITable;
    deleteTable(table: ITable): void;
    updateTableLabel(tableId: number, label: string): void;
    addTableParty(tableId: number, party: ITableParty): void;
    removeTableParty(tableId: number, party: ITableParty): void;
    allInvitees: ITableParty[];
    maxTableSize?: number;
}

const TableCard = (props: ITableCardProps) => {
    const {
        table,
        deleteTable,
        addTableParty,
        updateTableLabel,
        removeTableParty,
        allInvitees,
        maxTableSize = 8,
    } = props;

    const [additionalPartyTableId, setAdditionalPartyTableId] = useState<
        number | null
    >();
    const [additionalPartyName, setAdditionalPartyName] = useState<
        string | null
    >();

    const onUpdateLabel = useCallback(
        (newLabel: string) => updateTableLabel(table._id, newLabel),
        [updateTableLabel, table._id]
    );
    const onDelete = useCallback(
        () => deleteTable(table),
        [deleteTable, table]
    );
    const onAddParty = useCallback(() => {
        const party = allInvitees.find(
            ({ name }) => name === additionalPartyName
        );
        if (party) {
            addTableParty(table._id, party);
        }
        setAdditionalPartyTableId(null);
        setAdditionalPartyName(null);
    }, [addTableParty, additionalPartyName, allInvitees, table._id]);

    const allInviteeNames = useMemo(
        () => allInvitees.map(({ name }) => name),
        [allInvitees]
    );

    return (
        <Card minH="sm" minW="xs" outline="0.1px solid #63522f">
            <CardHeader>
                <HStack>
                    <Editable
                        value={table.label ?? `Table ${table._id}`}
                        onChange={onUpdateLabel}
                    >
                        <EditablePreview />
                        <EditableInput />
                    </Editable>
                    <Spacer />
                    <IconButton
                        size="xs"
                        icon={<BsXCircleFill />}
                        aria-label="Delete table"
                        onClick={onDelete}
                    />
                </HStack>
            </CardHeader>
            <CardBody>
                <List spacing={3}>
                    {table.parties.map((party) => (
                        <ListItem key={party.name}>
                            <TableCardRow
                                party={party}
                                onRemove={() =>
                                    removeTableParty(table._id, party)
                                }
                            />
                        </ListItem>
                    ))}
                </List>
                {table.parties.length < maxTableSize &&
                    isNil(additionalPartyTableId) && (
                        <Button
                            mt={3}
                            leftIcon={<AddIcon />}
                            onClick={() => setAdditionalPartyTableId(table._id)}
                        >
                            Add Invitee
                        </Button>
                    )}
                {additionalPartyTableId === table._id && (
                    <HStack mt={3}>
                        <Select
                            placeholder="Name"
                            onChange={({ target: { value } }) =>
                                setAdditionalPartyName(value)
                            }
                        >
                            {allInviteeNames.map((name) => (
                                <option key={name} value={name}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                        <IconButton
                            icon={<CheckIcon />}
                            aria-label="Add party"
                            onClick={onAddParty}
                        />
                    </HStack>
                )}
            </CardBody>
            <CardFooter>
                {table.parties.length}/{maxTableSize}
            </CardFooter>
        </Card>
    );
};

export default TableCard;
