import React from "react";
import Section from "./Section";
import RegistryLinkCard from "./RegistryLinkCard";
import { TfiGift } from "react-icons/tfi";
import { Wrap, WrapItem } from "@chakra-ui/react";

const RegistrySection = () => {
    return (
        <Section title="The Registry" id="registry">
            <Wrap spacing={8} justify="center">
                <WrapItem>
                    <RegistryLinkCard
                        title="Registry"
                        icon={TfiGift}
                        link="https://withjoy.com/dayle-and-ethan/registry"
                    />
                </WrapItem>
            </Wrap>
        </Section>
    );
};

export default RegistrySection;
