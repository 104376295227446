import { Divider, Heading, VStack } from "@chakra-ui/react";
import React from "react";

interface ISectionsProps {
    title: string;
    id: string;
    children: React.ReactNode;
    shouldShowTopDivider?: boolean;
}

const Section = (props: ISectionsProps) => {
    const { title, id, children, shouldShowTopDivider } = props;

    return (
        <VStack w="100%">
            {shouldShowTopDivider && <Divider />}
            <Heading size="2xl" id={id} mb={4} mt={6} fontFamily="QuestaGrande">
                {title}
            </Heading>
            {children}
        </VStack>
    );
};

export default Section;
