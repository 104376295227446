import React from "react";
import { Heading, Spacer, VStack } from "@chakra-ui/react";
import { useIsSmallScreen } from "../api/use-is-small-screen";

const Header = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <VStack my={16}>
            <Heading
                as="h1"
                size={["3xl", "2xl", "2xl", "2xl", "4xl"]}
                fontFamily="Juliette"
                lineHeight={10}
                whiteSpace="nowrap"
                flexWrap="nowrap"
                pointerEvents="none"
                userSelect="none"
                cursor="default"
            >
                Ethan Bütt {isSmallScreen && <Spacer minH="0.8rem" />}&{" "}
                {isSmallScreen && <Spacer minH="0.8rem" />}Dayle Meyer
            </Heading>
        </VStack>
    );
};

export default Header;
