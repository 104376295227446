import React, { useCallback, useEffect, useRef } from "react";
import {
    ChakraProvider,
    Box,
    Grid,
    Flex,
    extendTheme,
    createMultiStyleConfigHelpers,
    defineStyleConfig,
    defineStyle,
    // useToast,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { cardAnatomy, inputAnatomy } from "@chakra-ui/anatomy";
import Page from "./components/Page";
import Navigator from "./components/Navigator";
import RsvpPage from "./components/RsvpPage";
import { useIsSmallScreen } from "./api/use-is-small-screen";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import AdminPage from "./components/AdminPage";
import { useSetRecoilState } from "recoil";
import { IsConnected } from "./state/isConnected";
import TablesPage from "./components/TablesPage";
import Footer from "./components/Footer";
import { logUserEvent } from "./api/log-user-event";
import SecurityWall from "./components/SecurityWall";
import { LuConstruction } from "react-icons/lu";

const {
    definePartsStyle: defineButtonPartsStyle,
    defineMultiStyleConfig: defineButtonMultiStyleConfig,
} = createMultiStyleConfigHelpers(cardAnatomy.keys);

const {
    definePartsStyle: defineInputPartsStyle,
    defineMultiStyleConfig: defineInputMultiStyleConfig,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputTheme = defineInputMultiStyleConfig({
    baseStyle: defineInputPartsStyle({
        field: {
            borderColor: "gray.600",
            // _focusVisible: {
            //     borderColor: "red",
            // },
        },
    }),
});

const cardTheme = defineButtonMultiStyleConfig({
    baseStyle: defineButtonPartsStyle({
        container: {
            bgColor: "#f4f0e8",
            borderColor: "gray.600",
        },
    }),
});

const buttonTheme = defineStyleConfig({
    variants: {
        outline: defineStyle({
            borderColor: "gray",
            _hover: {
                backgroundColor: "#e5dcca",
            },
        }),
        solid: defineStyle({
            backgroundColor: "#d3c3a3",
            _hover: {
                backgroundColor: "#e5dcca",
            },
        }),
    },
});

const theme = extendTheme({
    styles: {
        global: (props: StyleFunctionProps) => ({
            body: {
                bg: mode("#f4f0e8", "black")(props),
            },
        }),
    },
    components: { Card: cardTheme, Button: buttonTheme, Input: inputTheme },
});

const SiteContent = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <>
            <Flex columnGap={10}>
                {!isSmallScreen && (
                    <Box
                        scrollBehavior="smooth"
                        top="20vh"
                        bottom="20vh"
                        pos="sticky"
                        alignSelf="center"
                        my="auto"
                        minW="fit-content"
                        h="60vh"
                        className="navigator"
                    >
                        <Navigator />
                    </Box>
                )}
                <Page />
            </Flex>
            <Footer />
        </>
    );
};

export const App = () => {
    const setIsConnected = useSetRecoilState(IsConnected);
    // const toast = useToast();

    const onConnectionLost = useCallback(() => {
        setIsConnected(false);
        // toast({
        //     title: "Connection Lost",
        //     description: "You are not connected to the internet.",
        //     status: "warning",
        //     duration: 8000, // ms
        //     isClosable: true,
        // });
    }, [setIsConnected]);
    const onConnectionEstablished = useCallback(
        () => setIsConnected(true),
        [setIsConnected]
    );

    useEffect(() => {
        window.addEventListener("offline", onConnectionLost);
        window.addEventListener("online", onConnectionEstablished);
        return () => {
            window.removeEventListener("offline", onConnectionLost);
            window.removeEventListener("online", onConnectionEstablished);
        };
    }, [onConnectionLost, onConnectionEstablished]);

    const logUserVisit = useCallback(async () => {
        await logUserEvent("Site loaded");
    }, []);

    const didLogSiteLoad = useRef(false);
    useEffect(() => {
        const path = window.location.pathname;
        if (path === "/" && !didLogSiteLoad.current) {
            didLogSiteLoad.current = true;
            logUserVisit();
        }
    }, [logUserVisit]);

    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Router>
                    <Grid minH="100vh" p={3}>
                        {/* <SecurityWall
                            Icon={LuConstruction}
                            passcode="9854"
                            message="Under Construction"
                            secondaryMessage="Pardon the intrusion — we will be back up and running soon"
                            ctaMessage="Access to this site currently requires an access code"
                        > */}
                        <Routes>
                            <Route
                                path="/admin/tables"
                                element={<TablesPage />}
                            />
                            <Route path="/admin" element={<AdminPage />} />
                            {/* <Route path="/rsvp" element={<RsvpPage />} /> */}
                            <Route path="/" element={<SiteContent />} />
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                        {/* </SecurityWall> */}
                    </Grid>
                </Router>
            </Box>
        </ChakraProvider>
    );
};
