import { HStack, Link, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineMail } from "react-icons/hi";

interface IEmailLinkProps {
    email: string;
}

const EmailLink = (props: IEmailLinkProps) => {
    const { email } = props;

    if (email.length === 0) {
        return (
            <HStack>
                <HiOutlineMail />
                <Text>-</Text>
            </HStack>
        );
    }

    return (
        <Link color="blue.600" href={`mailto:${email}`}>
            <HStack>
                <HiOutlineMail />
                <Text>{email}</Text>
            </HStack>
        </Link>
    );
};

export default EmailLink;
