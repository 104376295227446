import React from "react";
import { Link, Text } from "@chakra-ui/react";
import Section from "./Section";
import FAQList from "./FqaList";

const qs = [
    {
        question: "May I bring a guest not listed on my invitation?",
        answer: "We are only able to accomodate those named on your invitation.",
    },
    {
        question: "May I bring my children?",
        answer: "Although we love your children, we ask that you leave the little ones at home — enjoy a date night!",
    },
    {
        question: "Where can I park?",
        answer: "The Fitzgerald does not have a public parking lot; parking is available on several surrounding streets.",
    },
    {
        question: "Will we be indoors or outdoors?",
        answer: "Weather permitting, the ceremony will be held outdoors. Cocktail hour will be indoors and outdoors. Dinner and dancing will be indoors.",
    },
    {
        question: "May I take photos during the ceremony?",
        answer: "We hired an amazing photographer to capture all the best moments during our ceremony, so you shouldn't worry yourself with taking your own. Please refrain from using your phone during the ceremony.",
    },
    {
        question: "Is there somewhere to stay overnight before and after?",
        answer: (
            <Text>
                There are a number of hotels in the Milwaukee area. A few,
                listed in order of increasing distance from The Fitzgerald:{" "}
                <Link
                    color="blue.600"
                    href="https://www.knickerbockeronthelake.com"
                    isExternal
                >
                    Knickerbocker on the Lake
                </Link>{" "}
                ●{" "}
                <Link
                    color="blue.600"
                    href="https://plazahotelmilwaukee.com"
                    isExternal
                >
                    The Plaza Hotel
                </Link>{" "}
                ●{" "}
                <Link
                    color="blue.600"
                    href="https://www.thedubbeldutch.com"
                    isExternal
                >
                    The Dubbel Dutch
                </Link>{" "}
                ●{" "}
                <Link
                    color="blue.600"
                    href="https://www.marriott.com/en-us/hotels/mkedn-milwaukee-marriott-downtown/overview"
                    isExternal
                >
                    Milwaukee Marriot Downtown
                </Link>
                {/* There are a small allotment of rooms pre-reserved at{" "}
                <Link color="blue.600" href="https://ethanbutt.com">
                    The Placeholder Hotel
                </Link>{" "}
                for August 22 and 23 under "Butt Wedding." You will still need
                to arrange reservations within this allotment. */}
            </Text>
        ),
    },
    {
        question: "What if I have other questions?",
        answer: (
            <Text>
                Please direct any other questions to the maid of honor, Sophia
                Schauz
                {/* :{" "}
                <Link color="blue.600" href="tel:12626460910">
                    1 (262) 646 - 0910
                </Link> */}
                .
            </Text>
        ),
    },
];

const FaqSection = () => {
    return (
        <Section title="The Frequently Asked Questions" id="faq">
            <Text fontSize="medium" as="i" mb={4}>
                Got questions? Get answers.
            </Text>
            <FAQList questions={qs} />
        </Section>
    );
};

export default FaqSection;
