import { List, SimpleGrid } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import FAQ from "./Faq";

interface IFAQListProps {
    questions: {
        question: string;
        answer: string | ReactNode;
    }[];
}

const FAQList = (props: IFAQListProps) => {
    const { questions } = props;

    return (
        <List spacing={3} px={8}>
            <SimpleGrid columns={[1, 1, 2]} spacing={12} columnGap={24}>
                {questions.map((faq) => (
                    <FAQ key={faq.question} {...faq} />
                ))}
            </SimpleGrid>
        </List>
    );
};

export default FAQList;
