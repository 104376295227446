import { getServerAddress } from "./get-server-address";

interface IUserData {
    userAgent: string;
    windowWidth: number;
    maxTouchPoints: number;
}

export async function logUserEvent(event: string): Promise<boolean> {
    const userData: IUserData = {
        userAgent: navigator.userAgent,
        windowWidth: window.innerWidth,
        maxTouchPoints: navigator.maxTouchPoints,
    };
    const didSucceed: boolean = await fetch(
        `${getServerAddress()}/log-user-event`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userData, event }),
        }
    )
        .then((res) => res.json())
        .catch(() => {});
    return !!didSucceed;
}
