import { Assets } from "./assets";
import { IPartyMember } from "./types";

export const members: { [type: string]: IPartyMember[] } = {
    groomsmen: [
        {
            name: "Eric Powell",
            image: Assets.Eric,
            specialDesignationLabel: "Best man",
            detail: "Eric and Ethan grew up at the same church, where they both volunteered helping with audio and video for services. They eventually attended the same high school and then university. To anyone who has spent time with them, it comes as no surprise that Eric is one of Ethan's best friends. Ethan admires Eric's drive and his wit — except when that wit is turned on him, then it is just annoying.",
            designation: "groomsman",
        },
        {
            name: "Max Ketter",
            image: Assets.Max,
            specialDesignationLabel: "Best man",
            detail: "Max and Ethan met in high school. They became friends around the same time they discovered they both had exceptional taste in music. They went on to live together for a year in college. Max is an indispensable friend to Ethan. You can catch them discussing issues of philosophy and theology as casually as they do the last movie they saw. Go ahead, ask him about Carl Jung.",
            designation: "groomsman",
        },
        {
            name: "Adam Mueller",
            image: Assets.Adam,
            detail: 'Adam and Ethan have been neighbors and friends since they were wee lads. Adam has commonly been quoted as saying "Ethan moving in next door is the best thing that has ever happened to me." Yes, he really says that. They grew up together, and it would be tough today for Ethan to say Adam is a friend; they are much closer to family.',
            designation: "groomsman",
        },
        {
            name: "Liam Butt",
            image: Assets.Liam,
            detail: "Beginning on January 23, 2009, Ethan was no longer an only child. He no longer received all the gifts, all the attention, all the— he even lost his room to that kid. In exchange, he gained a brother and a friend that he is honored to have standing at his wedding.",
            designation: "groomsman",
        },
        {
            name: "Andrew Roberts",
            image: Assets.Andrew,
            detail: '"Andrew Rabbits." Capture the moment! This groomsman may not be stolen, abducted, or harmed in any way by any card until the end of the owner\'s next turn. Andrew and Ethan have been friends since Kindergarten. One thing you should know about them: they are always working on a project. They have worked together as actors, writers, publishers, game creators, and authors, among other roles.',
            designation: "groomsman",
        },
    ],
    bridesmaids: [
        {
            name: "Sophia Schauz",
            image: Assets.Sophia,
            specialDesignationLabel: "Maid of Honor",
            detail: "Sophia and Dayle have been best friends for 7 years. They've giggled their way through many years of leading retreats, attending small groups, and working at Camp Vertical. Sophia is kind, generous, and, most importantly, she's the life of the party. If you catch her on the big day, ask her to show you her Elvis impersonation!",
            designation: "bridesmaid",
        },
        {
            name: "Emma Schultz",
            image: Assets.Emma,
            detail: "Emma is one of Dayle's closest friends. They also met working at Camp Vertical back in 2018! Since then, the two have grown through all of the hard stuff together and celebrated countless happy moments! Emma has some serious dance moves that she can't wait to show off. If you need a dance partner, just find Emma.",
            designation: "bridesmaid",
        },
        {
            name: "Hannah O'Brien",
            image: Assets.Hannah,
            detail: "Hannah has arguably seen the worst of all members of the bridal party, as she used to be their small group leader in high school. Despite all of that, she still stuck around! Hannah is the one you call if you need someone to get you out of jail; remember that in case the reception gets out of hand. No promises that she won't make you listen to her sing Josh Groban's \"You Raise Me Up,\" though.",
            designation: "bridesmaid",
        },
    ],
};
