type EnvVarKey = "LOCAL_SERVER_URL" | "REMOTE_SERVER_URL" | "ADMIN_PASSCODE";

/**
 * Retrieve an environment variable value for a given key
 * @param varKey
 * @returns the value of the environment value
 */
export function getEnvVariable(varKey: EnvVarKey): string | undefined {
    return process.env[`REACT_APP_${varKey}`];
}

export function isLocal(): boolean {
    return process.env[`REACT_APP_IS_LOCAL_DEPLOYMENT`] === "true";
}
