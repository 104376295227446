import React, { useCallback } from "react";
import { VStack, Heading, Text, Button, HStack } from "@chakra-ui/react";
import { TbHome, TbReload } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    const onGoHome = useCallback(() => navigate("/"), [navigate]);
    const onReload = useCallback(() => window.location.reload(), []);

    return (
        <VStack spacing={8} mt={5} align="center">
            <Heading size="4xl">Uh oh!</Heading>
            <Text>Something went wrong.</Text>
            <HStack my="auto">
                <Button onClick={onGoHome} leftIcon={<TbHome />}>
                    Home
                </Button>
                <Button onClick={onReload} leftIcon={<TbReload />}>
                    Reload Page
                </Button>
            </HStack>
        </VStack>
    );
};

export default ErrorPage;
