import React from "react";
import { Text, Image, Heading, Box } from "@chakra-ui/react";
import Section from "./Section";
import { Assets } from "../assets";
import { useIsSmallScreen } from "../api/use-is-small-screen";

const CoupleSection = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <Section title="The Couple" id="couple">
            <Box maxW={isSmallScreen ? "90%" : "40%"}>
                <Image
                    src={Assets.Couple}
                    aspectRatio={1}
                    fit="cover"
                    pointerEvents="none"
                    userSelect="none"
                    onContextMenu={() => false}
                />
            </Box>
            <Heading size="xl">Ethan & Dayle</Heading>
            <Text>
                Ethan and Dayle both grew up attending Elmbrook Church. Although
                they never officially crossed paths, it is likely they were on
                many of the same retreats, attended the same camps, and
                participated in the same services. That changed Dec 24, 2019,
                when they sat next to each other during a Christmas Eve service.
                Two years later, they sat next to each other again, this time at
                the Camp Vertical "Paperwork Party" — they were both serving on
                staff that summer. On July 4, 2021, Dayle drove to Madison to
                see some fireworks with Ethan, and from that moment they could
                never even be apart.
            </Text>
            {/* <Heading size="xl">Ethan</Heading>
            <Text>
                Meet Ethan, the visionary creator behind this digital
                masterpiece. With a passion for innovation and an eye for
                detail, Ethan crafted this website from the ground up, infusing
                it with his expertise and creative flair. His dedication to user
                experience and unwavering commitment to excellence are evident
                in every pixel, a testament to his drive and ingenuity as the
                mastermind behind this online creation.
            </Text>
            <Heading size="xl">Dayle</Heading>
            <Text>
                Dayle, the epitome of grace and beauty, graces the world with an
                ethereal presence that captivates hearts far and wide. Her
                radiant smile illuminates even the darkest corners, while her
                kind soul and gentle spirit enchant all who are fortunate enough
                to cross her path. With an aura that rivals the morning sun and
                a heart as pure as the morning dew, Dayle stands as the paragon
                of elegance and kindness, the fairest maiden in every sense of
                the word.
            </Text> */}
        </Section>
    );
};

export default CoupleSection;
