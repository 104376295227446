export function formatPhoneNumberString(phone: string): string {
    const cleaned = phone.replace(/\D/g, "");
    switch (cleaned.length) {
        case 4:
        case 5:
        case 6:
            return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3)}`;
        case 7:
        case 8:
        case 9:
        case 10:
            return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
                3,
                6
            )} - ${cleaned.substring(6)}`;
        case 11:
            return `+${cleaned.substring(0, 1)} (${cleaned.substring(
                1,
                4
            )}) ${cleaned.substring(4, 7)} - ${cleaned.substring(7)}`;
        case 12:
            return `+${cleaned.substring(0, 2)} (${cleaned.substring(
                2,
                5
            )}) ${cleaned.substring(5, 8)} - ${cleaned.substring(8)}`;
        default:
            return cleaned;
    }
}

export function formatPhoneNumber(phone: number): string {
    const phoneString = phone.toString();
    return formatPhoneNumberString(phoneString);
}
