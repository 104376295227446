import { getServerAddress } from "./get-server-address";

export async function removeInvite(inviteId: number): Promise<number | null> {
    const didSucceed = await fetch(`${getServerAddress()}/remove-invite`, {
        method: "POST",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ inviteId }),
    }).then((res) => res.json());
    return didSucceed;
}
