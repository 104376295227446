import React from "react";
import {
    HStack,
    Heading,
    Image,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { FaRegStar } from "react-icons/fa";
import { useIsSmallScreen } from "../api/use-is-small-screen";

interface IProfileProps {
    name: string;
    detailText: string;
    photoPath: string;
    photoSize?: string | number;
    specialDesignation?: string;
}

const Profile = (props: IProfileProps) => {
    const {
        name,
        detailText,
        photoPath,
        photoSize = "8rem",
        specialDesignation,
    } = props;

    const isSmallScreen = useIsSmallScreen();

    return (
        <Stack direction={["column", "column", "column", "row"]}>
            <Image
                src={photoPath}
                borderRadius="full"
                boxSize={photoSize}
                fit="cover"
                aspectRatio={1}
                alignSelf={isSmallScreen ? "center" : undefined}
            />
            <Stack align={isSmallScreen ? "center" : "start"}>
                <HStack>
                    <Heading size="md">{name}</Heading>
                    {specialDesignation && (
                        <Tag
                            size="md"
                            bgColor="#63522f"
                            borderRadius="full"
                            columnGap={2}
                        >
                            <FaRegStar color="white" />
                            <TagLabel color="white">
                                {specialDesignation}
                            </TagLabel>
                        </Tag>
                    )}
                    {specialDesignation}
                </HStack>
                <Text textAlign="start">{detailText}</Text>
            </Stack>
        </Stack>
    );
};

export default Profile;
