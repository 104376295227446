import { IconType } from "react-icons";
import { BsFillCalendar2MonthFill } from "react-icons/bs";
import { LuPartyPopper, LuChurch } from "react-icons/lu";
import { FaEnvelopeOpen, FaGift, FaQuestion } from "react-icons/fa";
import { GiClothes } from "react-icons/gi";
import BasicsSection from "./components/BasicsSection";
import RsvpSection from "./components/RsvpSection";
import CoupleSection from "./components/CoupleSection";
import WeddingPartySection from "./components/WeddingPartySection";
import FaqSection from "./components/FaqSection";
import RegistrySection from "./components/RegistrySection";
import AttireSection from "./components/AttireSection";

interface IPageStep {
    id: number;
    label: string;
    Icon: IconType;
    anchor: string;
    SectionElement: React.ElementType;
}

const _steps: Omit<IPageStep, "id">[] = [
    {
        label: "Basics",
        Icon: BsFillCalendar2MonthFill,
        anchor: "basics",
        SectionElement: BasicsSection,
    },
    {
        label: "Couple",
        Icon: LuChurch,
        anchor: "couple",
        SectionElement: CoupleSection,
    },
    {
        label: "Wedding Party",
        Icon: LuPartyPopper,
        anchor: "party",
        SectionElement: WeddingPartySection,
    },
    // {
    //     label: "RSVP",
    //     Icon: FaEnvelopeOpen,
    //     anchor: "rsvp",
    //     SectionElement: RsvpSection,
    // },
    {
        label: "Attire",
        Icon: GiClothes,
        anchor: "attire",
        SectionElement: AttireSection,
    },
    {
        label: "Registry",
        Icon: FaGift,
        anchor: "registry",
        SectionElement: RegistrySection,
    },
    {
        label: "FAQ",
        Icon: FaQuestion,
        anchor: "faq",
        SectionElement: FaqSection,
    },
];

export const steps: IPageStep[] = _steps.map((s, index) => ({
    ...s,
    id: index,
}));
