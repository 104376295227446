import React, { ReactNode, useMemo } from "react";
import { Box, HStack, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { BsQuestionCircleFill } from "react-icons/bs";

interface IFAQProps {
    question: string;
    answer: string | ReactNode;
}

const FAQ = (props: IFAQProps) => {
    const { question, answer } = props;
    const isString = useMemo(() => typeof answer === "string", [answer]);

    return (
        <ListItem>
            <HStack>
                <ListIcon as={BsQuestionCircleFill} fontSize="3xl" />
                <Box textAlign="start">
                    <Text as="b">{question}</Text>
                    {isString ? <Text>{answer}</Text> : answer}
                </Box>
            </HStack>
        </ListItem>
    );
};

export default FAQ;
