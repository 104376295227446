import React, { useCallback, useState } from "react";
import {
    Card,
    CardBody,
    Icon,
    Link,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { logUserEvent } from "../api/log-user-event";

export interface IPinnedPageCardProps {
    title: string;
    icon?: IconType;
    link: string;
}

const RegistryLinkCard = (props: IPinnedPageCardProps) => {
    const { title, icon, link } = props;

    const [isHovered, setIsHovered] = useState(false);
    const onMouseEnter = useCallback(() => setIsHovered(true), []);
    const onMouseLeave = useCallback(() => setIsHovered(false), []);

    const onClick = useCallback(
        async () => await logUserEvent(`Registry: ${title}`),
        [title]
    );

    const outlineColor = useColorModeValue("gray", "white");

    return (
        <Link href={link} onClick={onClick} isExternal>
            <Card
                h="3xs"
                w={["2xs", "xs", "xl"]}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                outline={
                    isHovered ? `0.15rem solid ${outlineColor}` : undefined
                }
            >
                <CardBody>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        minH="100%"
                    >
                        <Icon fontSize="6xl" as={icon} />
                        <Text fontSize="3xl">{title}</Text>
                    </Stack>
                </CardBody>
            </Card>
        </Link>
    );
};

export default RegistryLinkCard;
