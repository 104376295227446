import { HStack, Link, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { formatPhoneNumberString } from "../api/format-phone-number";

interface IEmailLinkProps {
    phoneNumber: string;
    shouldHideIcon?: boolean;
}

const PhoneNumberLink = (props: IEmailLinkProps) => {
    const { phoneNumber, shouldHideIcon = false } = props;
    const formatted = useMemo(
        () => formatPhoneNumberString(phoneNumber),
        [phoneNumber]
    );

    if (phoneNumber.length === 0) {
        return (
            <HStack>
                <FaPhoneAlt />
                <Text>-</Text>
            </HStack>
        );
    }

    return (
        <Link color="blue.600" href={`tel:${phoneNumber.replace(/\D/g, "")}`}>
            <HStack>
                {!shouldHideIcon && <FaPhoneAlt />}
                <Text>{formatted}</Text>
            </HStack>
        </Link>
    );
};

export default PhoneNumberLink;
