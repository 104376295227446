import MaxImage from "./max.jpeg";
import EricImage from "./eric.jpeg";
import LiamImage from "./liam.jpeg";
import AndrewImage from "./andrew.jpeg";
import AdamImage from "./adam.jpeg";
import EthanImage from "./ethan.jpeg";
import DayleImage from "./dayle.jpeg";
import EmmaImage from "./emma.jpeg";
import SophiaImage from "./sophia.jpeg";
import HannahImage from "./hannah.jpeg";
import CoupleImage from "./couple.jpg";
import AttireGuide from "./attire-guide.png";

export const Assets: { [key: string]: string } = {
    Max: MaxImage,
    Eric: EricImage,
    Adam: AdamImage,
    Liam: LiamImage,
    Andrew: AndrewImage,
    Ethan: EthanImage,
    Dayle: DayleImage,
    Sophia: SophiaImage,
    Emma: EmmaImage,
    Hannah: HannahImage,
    Couple: CoupleImage,
    AttireGuide: AttireGuide,
};
