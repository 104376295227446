import React, { useMemo, useState } from "react";
import {
    HStack,
    PinInput,
    PinInputField,
    Text,
    VStack,
} from "@chakra-ui/react";
import { IconType } from "react-icons";

interface ISecurityWallProps {
    Icon?: IconType;
    children: React.ReactNode;
    passcode: string;
    timeout?: number;
    message?: string;
    secondaryMessage?: string;
    ctaMessage?: string;
}

const SecurityWall = (props: ISecurityWallProps) => {
    const {
        Icon,
        children,
        passcode,
        message = "Access to this content requires a passcode.",
        secondaryMessage,
        ctaMessage = "Enter code.",
    } = props;
    const [entry, setEntry] = useState("");
    const isLocked = useMemo(
        () => entry !== passcode.substring(0, 4),
        [entry, passcode]
    );

    if (isLocked) {
        return (
            <VStack h="calc(100vh - 10rem)" justifyContent="center">
                {Icon && <Icon size="15%" />}
                <Text as="b">{message}</Text>
                {secondaryMessage && <Text>{secondaryMessage}</Text>}
                <HStack my={2}>
                    <PinInput type="alphanumeric" mask onChange={setEntry}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
                <Text>
                    {isLocked && entry.length >= 4
                        ? "Nope, that's not it."
                        : ctaMessage}
                </Text>
            </VStack>
        );
    }

    return <>{children}</>;
};

export default SecurityWall;
