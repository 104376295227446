import React, { useCallback, useMemo, useState } from "react";
import {
    Heading,
    VStack,
    Text,
    Button,
    UnorderedList,
    HStack,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { IInvite, IRSVP, ITable, ITableParty } from "../types";
import { getAllInvites } from "../api/get-all-invites";
import { getAllRsvps } from "../api/get-all-rsvps";
import { AddIcon } from "@chakra-ui/icons";
import SecurityWall from "./SecurityWall";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import TableCard from "./TableCard";
import { snipe } from "../api/snipe";

const MAX_TABLE_SIZE = 8;

const TablesPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [invites, setInvites] = useState<IInvite[]>([]);
    const [rsvps, setRsvps] = useState<IRSVP[]>([]);
    const [tables, setTables] = useState<ITable[]>([{ _id: 1, parties: [] }]);

    const addTable = useCallback(
        () =>
            setTables((existing) => [
                ...existing,
                {
                    _id:
                        existing.length === 0
                            ? 1
                            : Math.max(...existing.map(({ _id }) => _id)) + 1,
                    parties: [],
                },
            ]),
        []
    );
    const deleteTable = useCallback(
        (table: ITable) =>
            setTables((existing) =>
                existing.filter(({ _id }) => _id !== table._id)
            ),
        []
    );
    const modifyTableLabel = useCallback(
        (tableId: number, newLabel: string) =>
            setTables((existing) => {
                const targetIndex = existing.findIndex(
                    ({ _id }) => _id === tableId
                );
                return snipe(existing, targetIndex, {
                    ...existing[targetIndex],
                    label: newLabel,
                });
            }),
        []
    );
    const addTableParty = useCallback(
        (tableId: number, newParty: ITableParty) =>
            setTables((existing) => {
                const targetIndex = existing.findIndex(
                    ({ _id }) => _id === tableId
                );
                return snipe(existing, targetIndex, {
                    ...existing[targetIndex],
                    parties: [...existing[targetIndex].parties, newParty],
                });
            }),
        []
    );
    const removeTableParty = useCallback(
        (tableId: number, targetParty: ITableParty) =>
            setTables((existing) => {
                const targetIndex = existing.findIndex(
                    ({ _id }) => _id === tableId
                );
                return snipe(existing, targetIndex, {
                    ...existing[targetIndex],
                    parties: existing[targetIndex].parties.filter(
                        (p) =>
                            p.inviteId !== targetParty.inviteId ||
                            p.name !== targetParty.name
                    ),
                });
            }),
        []
    );

    const onRefresh = useCallback(async () => {
        setIsLoading(true);
        setInvites((await getAllInvites()) ?? []);
        setRsvps((await getAllRsvps()) ?? []);
        setIsLoading(false);
    }, []);

    const inviteeCount = useMemo(
        () => invites.reduce((total, i) => total + i.inviteeNames.length, 0),
        [invites]
    );

    const possibleAttendeeCount = useMemo(
        () =>
            invites.reduce(
                (total, i) =>
                    total +
                    i.inviteeNames.filter(
                        (n) =>
                            rsvps
                                .find(({ _id }) => _id === i._id)
                                ?.parties.find(({ name }) => name === n)
                                ?.isAttending !== false
                    ).length,
                0
            ),
        [invites, rsvps]
    );

    const unassignedInvitees = useMemo(
        () =>
            invites.reduce<ITableParty[]>(
                (all, i) => [
                    ...all,
                    ...i.inviteeNames
                        .filter(
                            (n) =>
                                tables.every(
                                    (table) =>
                                        table.parties.findIndex(
                                            ({ name }) => name === n
                                        ) === -1
                                ) &&
                                rsvps
                                    .find(({ _id }) => _id === i._id)
                                    ?.parties.find(({ name }) => name === n)
                                    ?.isAttending !== false
                        )
                        .map((n) => ({
                            name: n,
                            inviteId: i._id,
                        })),
                ],
                []
            ),
        [invites, tables, rsvps]
    );

    return (
        <SecurityWall passcode="1235">
            <VStack spacing={8}>
                <HStack justifyContent="space-between">
                    <Heading>Reception Tables</Heading>
                    <ColorModeSwitcher />
                </HStack>
                <HStack>
                    <Button
                        onClick={onRefresh}
                        leftIcon={<IoCloudDownloadSharp />}
                        isLoading={isLoading}
                    >
                        Refresh Invites
                    </Button>
                    <Button
                        onClick={addTable}
                        leftIcon={<AddIcon />}
                        isDisabled={isLoading}
                    >
                        Add Table
                    </Button>
                </HStack>
                <UnorderedList mt={5}>
                    <Text>
                        Possible Attendees: {possibleAttendeeCount} (
                        {inviteeCount} invited)
                    </Text>
                    <Text>Max Seats Per Table: {MAX_TABLE_SIZE}</Text>
                    <Text>
                        Tables: {tables.length} (
                        {Math.ceil(inviteeCount / MAX_TABLE_SIZE)} recommended)
                    </Text>
                    <Text>
                        Unassigned Attendees: {unassignedInvitees.length}
                    </Text>
                    <Text fontSize="sm">
                        Guaranteed Minimum Seats Left Open:{" "}
                        {Math.ceil(inviteeCount / MAX_TABLE_SIZE) *
                            MAX_TABLE_SIZE -
                            inviteeCount}
                    </Text>
                </UnorderedList>
                <Wrap minW="100%" justify="center">
                    {tables.map((table) => (
                        <WrapItem key={table._id}>
                            <TableCard
                                table={table}
                                deleteTable={deleteTable}
                                updateTableLabel={modifyTableLabel}
                                addTableParty={addTableParty}
                                removeTableParty={removeTableParty}
                                allInvitees={unassignedInvitees}
                                maxTableSize={MAX_TABLE_SIZE}
                            />
                        </WrapItem>
                    ))}
                </Wrap>
            </VStack>
        </SecurityWall>
    );
};

export default TablesPage;
