import React, { useCallback } from "react";
import {
    Button,
    HStack,
    IconButton,
    Input,
    ListItem,
    OrderedList,
    Stack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

export interface INewInvitee {
    id: number;
    name: string;
}

interface INewInviteeListProps {
    attendees: INewInvitee[];
    onAdd(attendee: Omit<INewInvitee, "id">): void;
    onRemove(id: number): void;
    onSetAttendeeName(id: number, name: string): void;
}

const defaultNewAttendee: Omit<INewInvitee, "id"> = { name: "" };

const NewInviteeList = (props: INewInviteeListProps) => {
    const { attendees, onAdd, onRemove, onSetAttendeeName } = props;

    const onAddAttendee = useCallback(() => onAdd(defaultNewAttendee), [onAdd]);
    const onChange = useCallback(
        (id: number, name: string) => onSetAttendeeName(id, name),
        [onSetAttendeeName]
    );

    return (
        <Stack>
            <OrderedList spacing={4}>
                {attendees.map((attendee) => (
                    <ListItem key={attendee.id} w="100%">
                        <HStack>
                            <Input
                                value={attendee.name}
                                onChange={({ target: { value } }) =>
                                    onChange(attendee.id, value)
                                }
                            />
                            <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => onRemove(attendee.id)}
                                aria-label="Remove attendee"
                                alignSelf="end"
                            />
                        </HStack>
                    </ListItem>
                ))}
            </OrderedList>
            <Button
                mt={attendees.length > 0 ? 4 : 0}
                onClick={onAddAttendee}
                leftIcon={<AddIcon />}
            >
                Add Attendee
            </Button>
        </Stack>
    );
};

export default NewInviteeList;
