import React from "react";
import { Text, Image } from "@chakra-ui/react";
import Section from "./Section";
import { useIsSmallScreen } from "../api/use-is-small-screen";
import { Assets } from "../assets";

const AttireSection = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <Section title="The Attire" id="attire">
            <Image
                src={Assets.AttireGuide}
                maxW={isSmallScreen ? "90%" : "40%"}
                pointerEvents="none"
                userSelect="none"
            />
            <Text mt={4}>
                The dress code is formal. Use the above photos as inspiration if
                you need it!
            </Text>
            <Text fontSize="small">
                (For the men: bow ties are not required; their prevalence in
                these photos is just a coincidence)
            </Text>
        </Section>
    );
};

export default AttireSection;
