import { useCallback } from "react";
import {
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Link,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import Section from "./Section";
import { useIsSmallScreen } from "../api/use-is-small-screen";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { copyTextToClipboard } from "../api/copy-text-to-clipboard";
import { logUserEvent } from "../api/log-user-event";

const BasicsSection = () => {
    const isSmallScreen = useIsSmallScreen();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onClick = useCallback(async () => {
        onOpen();
        await logUserEvent("Venue detail modal opened");
    }, [onOpen]);

    const toast = useToast();
    const onTapAddress = useCallback(
        () =>
            copyTextToClipboard(
                "1119 N Marshall Street Milwaukee, WI 53202",
                () => {
                    toast.closeAll();
                    toast({
                        title: "Copied!",
                        description:
                            "The address has been copied to your clipboard.",
                        status: "success",
                        duration: 6000, // ms
                        isClosable: true,
                    });
                }
            ),
        [toast]
    );

    return (
        <Section title="The Basics" id="basics">
            <Stack direction={["column", "column", "column", "row"]} mb={4}>
                <Text
                    variant="link"
                    fontSize="x-large"
                    color="black"
                    onClick={onClick}
                    _hover={{ textDecoration: "underline" }}
                >
                    The Fitzgerald
                </Text>
                <Divider
                    orientation={isSmallScreen ? "horizontal" : "vertical"}
                    borderLeftWidth={2}
                    borderColor="ButtonText"
                />
                <Text fontSize="x-large">Friday, August 23, 2024</Text>
            </Stack>
            <Text maxW="50%" mb={4}>
                Please consider time to park and find your seats,
                <br />
                as the ceremony will begin promptly at 4:30 PM.
            </Text>
            <AddToCalendarButton
                name="Bütt/Meyer Wedding"
                description="https://www.ethananddayle.com"
                options={["Apple", "Google", "iCal", "Outlook.com"]}
                location="The Fitzgerald 1119 N Marshall Street Milwaukee, WI 53202"
                startDate="2024-08-23"
                endDate="2024-08-23"
                startTime="14:00"
                endTime="23:59"
                timeZone="America/Chicago"
                organizer="Ethan Butt|ethan@ethanbutt.com"
                availability="busy"
                listStyle="overlay"
                // styleLight="box-shadow: none;"
                // buttonStyle="date"
            />

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                closeOnOverlayClick
                closeOnEsc
            >
                <ModalOverlay />
                <ModalContent pb={4}>
                    <ModalHeader>The Fitzgerald</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={4} onClick={onTapAddress} userSelect="all">
                            1119 N Marshall Street
                            <br />
                            Milwaukee, WI 53202
                        </Text>
                        <HStack spacing={6}>
                            <Link
                                href="https://maps.apple.com/?address=1119%20N%20Marshall%20St,%20Milwaukee,%20WI%20%2053202,%20United%20States&ll=43.045217,-87.901228&q=The%20Fitzgerald"
                                isExternal
                            >
                                Apple Maps <ExternalLinkIcon />
                            </Link>
                            <Link
                                href="https://maps.app.goo.gl/DVs5GP9WWV7CC622A"
                                isExternal
                            >
                                Google Maps <ExternalLinkIcon />
                            </Link>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Section>
    );
};

export default BasicsSection;
