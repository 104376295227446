import React, { useCallback } from "react";
import {
    Divider,
    IconButton,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Link,
    UnorderedList,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { LuInfo } from "react-icons/lu";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { logUserEvent } from "../api/log-user-event";

const Footer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onClick = useCallback(async () => {
        onOpen();
        await logUserEvent("Footer modal opened");
    }, [onOpen]);

    return (
        <VStack mt="auto" mb={5}>
            <Divider size="lg" borderColor="grey.700" />
            <IconButton
                onClick={onClick}
                icon={<LuInfo />}
                variant="link"
                aria-label="View website information"
            />

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                closeOnOverlayClick
                closeOnEsc
            >
                <ModalOverlay />
                <ModalContent pb={4}>
                    <ModalHeader>Information</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Link
                            href="https://www.freeprivacypolicy.com/live/6a7b8933-b6c0-48b3-b1c6-f677b1911e86"
                            isExternal
                        >
                            Privacy Policy <ExternalLinkIcon />
                        </Link>
                        <Text mb={4} mt={6}>
                            With the exceptions of images and icons, which
                            belong to their original creators, all content on
                            this wesite was designed and created by Ethan Butt.
                        </Text>
                        <Text>Created using</Text>
                        <UnorderedList pl={4}>
                            <ListItem>
                                <Link href="https://chakra-ui.com" isExternal>
                                    Chakra UI <ExternalLinkIcon />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.mongodb.com" isExternal>
                                    MongoDB Atlas <ExternalLinkIcon />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://aws.amazon.com" isExternal>
                                    Amazon AWS <ExternalLinkIcon />
                                </Link>
                            </ListItem>
                        </UnorderedList>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default Footer;
