import React, { useCallback, useMemo, useState } from "react";
import {
    Heading,
    VStack,
    Text,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    UnorderedList,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    useToast,
    Divider,
    Tooltip,
    Stack,
    PopoverTrigger,
    Popover,
    PopoverContent,
    Portal,
    PopoverArrow,
    PopoverBody,
    TableCaption,
    ListItem,
} from "@chakra-ui/react";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { IInvite, IRSVP, ISiteUserEvent } from "../types";
import { getAllInvites } from "../api/get-all-invites";
import { getAllRsvps } from "../api/get-all-rsvps";
import PhoneNumberLink from "./PhoneNumberLink";
import EmailLink from "./EmailLink";
import { AddIcon, DeleteIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons";
import NewInviteeList, { INewInvitee } from "./NewInviteeList";
import { addInvite } from "../api/add-invite";
import { removeInvite } from "../api/remove-invite";
import SecurityWall from "./SecurityWall";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { MdOutlineNotes } from "react-icons/md";
import { getSiteVisitCount } from "../api/get-site-visit-count";
import { getSiteUserInteractions } from "../api/get-site-user-interactions";
import { UAParser } from "ua-parser-js";
import { getEnvVariable } from "../api/get-env-variable";
import { isNil } from "../api/is-nil";

function getSubmissionDateString(dt?: Date) {
    if (!dt) {
        return "";
    }

    const date = new Date(dt);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

interface IRowData extends IInvite {
    rsvp?: IRSVP;
}

const AdminPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [invites, setInvites] = useState<IInvite[]>([]);
    const [rsvps, setRsvps] = useState<IRSVP[]>([]);

    const [siteUserInteractions, setSiteUserInteractions] = useState<
        ISiteUserEvent[]
    >([]);
    const siteUserInteractionsOrdered = useMemo(
        () => siteUserInteractions.slice().reverse(),
        [siteUserInteractions]
    );
    const [siteVisitCount, setSiteVisitCount] = useState(0);

    const [isDeleting, setIsDeleting] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newInvitees, setNewInvitees] = useState<INewInvitee[]>([]);
    const addAdditionalParty = useCallback(
        (attendee: Omit<INewInvitee, "id">) => {
            const newId = newInvitees.reduce(
                (largestId, { id: thisId }) =>
                    Math.max(largestId, thisId + 1, 0),
                0
            );

            setNewInvitees((prev) => [...prev, { ...attendee, id: newId }]);
        },
        [newInvitees, setNewInvitees]
    );
    const removeAdditionalParty = useCallback(
        (attendeeId: number) =>
            setNewInvitees((prev) => prev.filter((p) => p.id !== attendeeId)),
        [setNewInvitees]
    );
    const updateAdditionalParty = useCallback(
        (id: number, name: string) => {
            const targetIndex = newInvitees.findIndex((p) => p.id === id);
            const firstHalf =
                targetIndex > 0 ? newInvitees.slice(0, targetIndex) : [];
            const secondHalf =
                targetIndex < newInvitees.length - 1
                    ? newInvitees.slice(targetIndex + 1)
                    : [];

            setNewInvitees([...firstHalf, { id, name }, ...secondHalf]);
        },
        [newInvitees, setNewInvitees]
    );

    const onRefresh = useCallback(async () => {
        setIsLoading(true);
        setInvites((await getAllInvites()) ?? []);
        setRsvps((await getAllRsvps()) ?? []);
        setSiteVisitCount(await getSiteVisitCount());
        setSiteUserInteractions(await getSiteUserInteractions());
        setIsLoading(false);
    }, []);

    const getUserAgentDescription = useCallback((userAgentString: string) => {
        if (userAgentString.includes("Googlebot")) {
            return "Googlebot";
        }
        let parser = new UAParser(userAgentString);
        return `${parser.getBrowser().name} on ${
            parser.getDevice().model
        } running ${parser.getOS().name} ${parser.getOS().version}`;
    }, []);

    const toast = useToast();
    const onRemoveInvite = useCallback(
        async (id: number) => {
            setIsLoading(true);
            const didRemove = await removeInvite(id);
            if (didRemove) {
                setInvites((existing) => existing.filter((e) => e._id !== id));
                setRsvps((existing) => existing.filter((e) => e._id !== id));
                toast({
                    title: "See Ya!",
                    description: "The invite and linked RSVP were deleted.",
                    status: "success",
                    duration: 8000, // ms
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Uh Oh!",
                    description:
                        "The invite and linked RSVP could not be deleted.",
                    status: "error",
                    duration: 8000, // ms
                    isClosable: true,
                });
            }
            setIsLoading(false);
            setIsDeleting(false);
        },
        [toast]
    );

    const onCloseAddInvite = useCallback(() => {
        setNewInvitees([]);
        onClose();
    }, [onClose]);
    const onAddInvite = useCallback(async () => {
        setIsLoading(true);
        const inviteeNames = newInvitees.map(({ name }) => name.trim());
        const inviteId = await addInvite(inviteeNames);
        if (inviteId) {
            setInvites((prev) => [...prev, { _id: inviteId, inviteeNames }]);
            toast({
                title: "Invited!",
                description: "The invite has been created.",
                status: "success",
                duration: 8000, // ms
                isClosable: true,
            });
        } else {
            toast({
                title: "Uh Oh!",
                description: "The invite could not be created.",
                status: "error",
                duration: 8000, // ms
                isClosable: true,
            });
        }
        onCloseAddInvite();
        setIsLoading(false);
    }, [newInvitees, onCloseAddInvite, toast]);

    const rows = useMemo<IRowData[]>(
        () =>
            invites
                .map((invite) => {
                    const matchingRsvp = rsvps.find(
                        (rsvp) => rsvp._id === invite._id
                    );
                    return { ...invite, rsvp: matchingRsvp };
                })
                .sort((a, b) => (!!a.rsvp && !b.rsvp ? -1 : 1)),
        [invites, rsvps]
    );

    const attendingCount = useMemo(
        () =>
            rsvps.reduce(
                (total, r) =>
                    total + r.parties.filter((p) => p.isAttending).length,
                0
            ),
        [rsvps]
    );

    const notAttendingCount = useMemo(
        () =>
            rsvps.reduce(
                (total, r) =>
                    total + r.parties.filter((p) => !p.isAttending).length,
                0
            ),
        [rsvps]
    );

    const inviteeCount = useMemo(
        () => invites.reduce((total, i) => total + i.inviteeNames.length, 0),
        [invites]
    );

    const allRequests = useMemo(
        () =>
            rows.filter(
                ({ rsvp }) =>
                    !isNil(rsvp) &&
                    rsvp.request.length > 0 &&
                    ![
                        "no",
                        "nope",
                        "none",
                        "nothing",
                        "no.",
                        "nope.",
                        "none.",
                        "nothing.",
                    ].includes(rsvp.request.trim().toLowerCase())
            ),
        [rows]
    );

    const allComments = useMemo(
        () =>
            rows.filter(
                ({ rsvp }) =>
                    !isNil(rsvp) &&
                    rsvp.comment.length > 0 &&
                    ![
                        "no",
                        "nope",
                        "none",
                        "nothing",
                        "no.",
                        "nope.",
                        "none.",
                        "nothing.",
                    ].includes(rsvp.comment.trim().toLowerCase())
            ),
        [rows]
    );

    const passcode = useMemo(() => getEnvVariable("ADMIN_PASSCODE") ?? "", []);

    const userAgentMap = useMemo(() => {
        const map = new Map<string, string[]>();
        siteUserInteractions
            .filter((interaction) => interaction.event.includes("RSVP form"))
            .forEach((rsvpInteraction, index) => {
                const possibleName = rsvpInteraction.event
                    .slice(11)
                    .trim()
                    .toLowerCase();
                const matchingRow = rows.find(
                    (row) =>
                        row.inviteeNames.findIndex(
                            (inviteeName) =>
                                inviteeName.toLowerCase() === possibleName
                        ) > 0
                );

                if (matchingRow) {
                    const { userAgent } = rsvpInteraction;
                    const seenBefore =
                        map.has(userAgent) &&
                        map
                            .get(userAgent)!
                            .includes(matchingRow.inviteeNames[0]);
                    if (!seenBefore) {
                        map.set(
                            userAgent,
                            map.has(userAgent)
                                ? [
                                      ...map.get(userAgent)!,
                                      matchingRow.inviteeNames[0],
                                  ]
                                : [matchingRow.inviteeNames[0]]
                        );
                    }
                }
            });
        return map;
    }, [siteUserInteractions, rows]);

    return (
        <SecurityWall passcode={passcode}>
            <VStack spacing={8}>
                <HStack justifyContent="space-between">
                    <Heading>Invite Management</Heading>
                    <ColorModeSwitcher />
                </HStack>
                <HStack>
                    <Button
                        onClick={onRefresh}
                        leftIcon={<IoCloudDownloadSharp />}
                        isLoading={isLoading}
                    >
                        Refresh
                    </Button>
                    <Button
                        onClick={onOpen}
                        leftIcon={<AddIcon />}
                        isDisabled={isLoading}
                    >
                        Add Invite
                    </Button>
                    <Button
                        onClick={() => setIsDeleting((current) => !current)}
                        leftIcon={isDeleting ? <LockIcon /> : <UnlockIcon />}
                        isDisabled={isLoading}
                    >
                        {isDeleting ? "Lock Deletion" : "Allow Deletion"}
                    </Button>
                </HStack>
                <UnorderedList mt={5}>
                    <Text>Site Visits: {siteVisitCount}</Text>
                    <Divider borderColor="black" />
                    <Text>Invites Out: {invites.length}</Text>
                    <Text>RSVPs Submitted: {rsvps.length}</Text>
                    <Divider borderColor="black" />
                    <Text>Invited: {inviteeCount}</Text>
                    <Text>Attending: {attendingCount}</Text>
                    <Text>Not Attending: {notAttendingCount}</Text>
                    <Text>
                        Indeterminate:{" "}
                        {inviteeCount - attendingCount - notAttendingCount}
                    </Text>
                </UnorderedList>
                <Accordion allowToggle w="100%" overflowY="hidden">
                    <AccordionItem overflowY="scroll">
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                    Invite List
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {rows.length > 0 ? (
                                <TableContainer w="100%">
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>ID</Th>
                                                <Th>Parties</Th>
                                                <Th>Status</Th>
                                                <Th>Phone</Th>
                                                <Th>Email</Th>
                                                <Th>Actions</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rows.map((row) => (
                                                <Tr key={row._id}>
                                                    <Td>{row._id}</Td>
                                                    <Td>
                                                        {row.inviteeNames
                                                            .map(
                                                                (name) =>
                                                                    `${name} ${
                                                                        row.rsvp
                                                                            ? row.rsvp.parties.find(
                                                                                  (
                                                                                      p
                                                                                  ) =>
                                                                                      p.name ===
                                                                                      name
                                                                              )
                                                                                  ?.isAttending
                                                                                ? "✔︎"
                                                                                : "✕"
                                                                            : "❓"
                                                                    }`
                                                            )
                                                            .join(", ")}
                                                    </Td>
                                                    <Td>
                                                        <Tooltip
                                                            label={
                                                                <Stack
                                                                    spacing={0}
                                                                >
                                                                    <p>
                                                                        {getSubmissionDateString(
                                                                            row
                                                                                .rsvp
                                                                                ?.submittedAt
                                                                        )}
                                                                    </p>
                                                                    {row.rsvp
                                                                        ?.submittedAt !==
                                                                        row.rsvp
                                                                            ?.updatedAt && (
                                                                        <p>
                                                                            {getSubmissionDateString(
                                                                                row
                                                                                    .rsvp
                                                                                    ?.updatedAt
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </Stack>
                                                            }
                                                        >
                                                            {row.rsvp
                                                                ? "Submitted"
                                                                : "Incomplete"}
                                                        </Tooltip>
                                                    </Td>
                                                    <Td>
                                                        <PhoneNumberLink
                                                            phoneNumber={
                                                                row.rsvp
                                                                    ?.phone ??
                                                                ""
                                                            }
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <EmailLink
                                                            email={
                                                                row.rsvp
                                                                    ?.email ??
                                                                ""
                                                            }
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Popover isLazy>
                                                            <PopoverTrigger>
                                                                <IconButton
                                                                    icon={
                                                                        <MdOutlineNotes />
                                                                    }
                                                                    aria-label="Open notes"
                                                                    isDisabled={
                                                                        !row
                                                                            .rsvp
                                                                            ?.submittedAt
                                                                    }
                                                                />
                                                            </PopoverTrigger>
                                                            <Portal>
                                                                <PopoverContent w="-webkit-fit-content">
                                                                    <PopoverArrow />
                                                                    <PopoverBody>
                                                                        Comment:{" "}
                                                                        {row
                                                                            .rsvp
                                                                            ?.comment &&
                                                                        row.rsvp
                                                                            .comment
                                                                            .length
                                                                            ? row
                                                                                  .rsvp
                                                                                  .comment
                                                                            : "-"}
                                                                        <br />
                                                                        Song
                                                                        Request:{" "}
                                                                        {row
                                                                            .rsvp
                                                                            ?.request &&
                                                                        row.rsvp
                                                                            .request
                                                                            .length
                                                                            ? row
                                                                                  .rsvp
                                                                                  .request
                                                                            : "-"}
                                                                    </PopoverBody>
                                                                </PopoverContent>
                                                            </Portal>
                                                        </Popover>
                                                        <IconButton
                                                            ml={2}
                                                            backgroundColor="red.400"
                                                            icon={
                                                                <DeleteIcon />
                                                            }
                                                            onClick={async () =>
                                                                await onRemoveInvite(
                                                                    row._id
                                                                )
                                                            }
                                                            aria-label="Remove invite"
                                                            alignSelf="end"
                                                            isDisabled={
                                                                isLoading ||
                                                                !isDeleting
                                                            }
                                                            colorScheme="red"
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                        {rows.length > 10 && (
                                            <Tfoot>
                                                <Tr>
                                                    <Th>ID</Th>
                                                    <Th>Parties</Th>
                                                    <Th>Status</Th>
                                                    <Th>Phone</Th>
                                                    <Th>Email</Th>
                                                    <Th>Actions</Th>
                                                </Tr>
                                            </Tfoot>
                                        )}
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Text fontSize="sm" as="i">
                                    No invites.
                                </Text>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem overflowY="scroll">
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                    Song Requests
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <UnorderedList>
                                {allRequests.map((row) => (
                                    <ListItem key={row._id}>
                                        <Text>{row.rsvp!.request}</Text>
                                    </ListItem>
                                ))}
                            </UnorderedList>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem overflowY="scroll">
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                    Comments
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <UnorderedList>
                                {allComments.map((row) => (
                                    <ListItem key={row._id}>
                                        <Text>{row.rsvp!.comment}</Text>
                                    </ListItem>
                                ))}
                            </UnorderedList>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem overflowY="scroll">
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                    Site User Interactions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <TableContainer w="100%">
                                <Table variant="simple">
                                    <TableCaption>
                                        {siteUserInteractions.length} total
                                    </TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Timestamp</Th>
                                            <Th>Event</Th>
                                            <Th>User Agent</Th>
                                            <Th>User (Guess)</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {siteUserInteractionsOrdered.map(
                                            (row) => (
                                                <Tr key={row._id}>
                                                    <Td>
                                                        <Tooltip
                                                            label={row.timestamp.toLocaleString(
                                                                "en-US",
                                                                {
                                                                    year: "numeric",
                                                                    month: "short",
                                                                    day: "numeric",
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                    timeZoneName:
                                                                        "short",
                                                                }
                                                            )}
                                                        >
                                                            <Text>
                                                                {new Date(
                                                                    row.timestamp
                                                                ).toLocaleDateString(
                                                                    undefined,
                                                                    {
                                                                        weekday:
                                                                            "short",
                                                                        year: "numeric",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }
                                                                )}{" "}
                                                                {new Date(
                                                                    row.timestamp
                                                                ).toLocaleTimeString(
                                                                    undefined,
                                                                    {
                                                                        hour12: false,
                                                                        timeZone:
                                                                            "America/Chicago",
                                                                    }
                                                                )}
                                                            </Text>
                                                        </Tooltip>
                                                    </Td>
                                                    <Td>
                                                        <Text>{row.event}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Tooltip
                                                            label={`agent: ${row.userAgent}; width: ${row.windowWidth}; touchpoint: ${row.maxTouchPoints}`}
                                                        >
                                                            <Text>
                                                                {getUserAgentDescription(
                                                                    row.userAgent
                                                                )}
                                                            </Text>
                                                        </Tooltip>
                                                    </Td>
                                                    <Td>
                                                        {userAgentMap
                                                            .get(row.userAgent)
                                                            ?.map((name) => (
                                                                <Text id={name}>
                                                                    {name}
                                                                </Text>
                                                            ))}
                                                    </Td>
                                                </Tr>
                                            )
                                        )}
                                    </Tbody>
                                    {rows.length > 10 && (
                                        <Tfoot>
                                            <Tr>
                                                <Th>Timestamp</Th>
                                                <Th>Event</Th>
                                                <Th>User Agent</Th>
                                                <Th>User (Guess)</Th>
                                            </Tr>
                                        </Tfoot>
                                    )}
                                </Table>
                            </TableContainer>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Modal isOpen={isOpen && !isLoading} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Invite</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <NewInviteeList
                                attendees={newInvitees}
                                onAdd={addAdditionalParty}
                                onRemove={removeAdditionalParty}
                                onSetAttendeeName={updateAdditionalParty}
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="outline"
                                onClick={onCloseAddInvite}
                                mr={3}
                            >
                                Cancel
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={onAddInvite}
                                isDisabled={
                                    newInvitees.length === 0 ||
                                    newInvitees.some((i) => i.name.length === 0)
                                }
                            >
                                Add
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </SecurityWall>
    );
};

export default AdminPage;
