import React from "react";
import { Divider, List, ListItem, Spacer, Stack } from "@chakra-ui/react";
import Section from "./Section";
import Profile from "./Profile";
import { members } from "../wedding-party";
import { useIsSmallScreen } from "../api/use-is-small-screen";

const WeddingPartySection = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <Section title="The Wedding Party" id="party">
            <Stack direction={["column", "column", "column", "row"]}>
                <List spacing={12} w="fit-content">
                    {members.groomsmen.map(
                        ({ name, image, detail, specialDesignationLabel }) => (
                            <ListItem key={name}>
                                <Profile //Card
                                    name={name}
                                    detailText={detail}
                                    photoPath={image}
                                    specialDesignation={specialDesignationLabel}
                                />
                            </ListItem>
                        )
                    )}
                </List>
                {isSmallScreen ? (
                    <Spacer minH={12} />
                ) : (
                    <Divider
                        orientation="vertical"
                        borderLeftWidth={2}
                        borderColor="ButtonText"
                    />
                )}
                <List spacing={12} w="fit-content">
                    {members.bridesmaids.map(
                        ({ name, image, detail, specialDesignationLabel }) => (
                            <ListItem key={name}>
                                <Profile //Card
                                    name={name}
                                    detailText={detail}
                                    photoPath={image}
                                    specialDesignation={specialDesignationLabel}
                                />
                            </ListItem>
                        )
                    )}
                </List>
            </Stack>
        </Section>
    );
};

export default WeddingPartySection;
