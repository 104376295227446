const noOp = () => {};

/**
 * Copy text to clipboard.
 */
export async function copyTextToClipboard(
    text: string,
    onSuccess = noOp,
    onFail = noOp
) {
    await navigator.clipboard.writeText(text).then(onSuccess, onFail);
}
