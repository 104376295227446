import { IInvite } from "../types";
import { getServerAddress } from "./get-server-address";

export async function addInvite(
    inviteeNames: string[]
): Promise<number | null> {
    const invite: IInvite | null = await fetch(
        `${getServerAddress()}/add-invite`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(inviteeNames),
        }
    ).then((res) => res.json());
    return invite?._id ?? null;
}
